import * as React from "react";
import { withLayout } from "../components/Layout";
import Button, { ButtonTypes } from "../components/Button/Button";
import BookColophon from "../components/book-colophon/book-colophon";
import BookProductPreview from "../components/book-product-preview/book-product-preview";
import leftArrowCircle from "../css/svg/arrow-circle-left.svg";
import { style, classes } from "./typed-poems.st.css";

import coverPhoto from "../images/typed-poems-cover-large.jpg";
import tocExcerptPhoto from "../images/typed-poems-toc-page.jpg";

const productId = "%D7%A9%D7%99%D7%A8%D7%99%D7%9D-%D7%98%D7%99%D7%A4%D7%95%D7%A1%D7%99%D7%99%D7%9D";
const buyProductUrl = `//www.poetryplace.org/product/${productId}`;

const TypedPoemsPage = () => {
  return (
    <div className={style(classes.root)}>
      <div className={style(classes.topFold)}>
        <div className={style(classes.container)}>
          <div className={style(classes.grid)}>
            <div className={style(classes.row)}>
              <div className={style(classes.column, { stackable: true, columns: 2 })}>
                <div className={style(classes.figure)}>
                  <img
                    title={`שירים טיפוסיים &mdash; כריכת הספר`}
                    src={coverPhoto}
                    className={style(classes.coverPhoto)}
                  />
                </div>
              </div>
              <div className={style(classes.column, { stackable: true, columns: 2 })}>
                <BookProductPreview
                  title={`שירים טיפוסיים`}
                  content={`<em><%= title %></em> הוא אוסף שירים אוטומטיים וחצי־אוטומטיים,
                תפילות אשכבה לסלבּז,
                צ׳יטים, פּרצות אבטחה, סטורי אחד ושירים אחרים.
                זהו הספר הראשון לראות אור בסידרת נוסח לשירה`}
                />

                <Button
                  type={ButtonTypes.HREF}
                  className={style(classes.ctaButton)}
                  href={buyProductUrl}
                  target={`_blank`}
                  label={`לרכישת הספר`}
                  icon={leftArrowCircle}
                />

                <BookColophon
                  publicationYear={`2019`}
                  publicationTitle={`שירים טיפוסיים`}
                  publisherName={`מקום לשירה`}
                  publisherSeriesLabel={`נוסח`}
                  publisherSeriesDescription={`סידרה לשירה`}
                  publisherSeriesIndex={1}
                  editorCreditName={`אלכס בן־ארי`}
                  editorCreditLabel={`עורך הספר והסידרה`}
                  author={`תומר ליכטש`}
                  serialNumberType={`מסת״ב (ISBN)`}
                  serialNumberCode={`978-965-92723-2-7`}
                  coverPhotoLabel={`Test Card`}
                  coverPhotoAuthor={`דפנה שרתיאל`}
                  coverPhotoCreatedAt={`2010`}
                  isCoverPhotoDetail={`פרט`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style(classes.section)}>
        <div className={style(classes.container)}>
          <div className={style(classes.grid)}>
            <div className={style(classes.row)}>
              <div className={style(classes.column, { stackable: true, columns: 2 })}>
                <div className={style(classes.poemContent)}>
                  <div className={style(classes.poemHeader)}>
                    <p className={style(classes.poemLabel)}>שני קטעים מתוך השיר:</p>
                    <p className={style(classes.poemTitle)}>שלום לכם, ובוקר טוב</p>
                  </div>
                  <p>שָׁלוֹם לָכֶם</p>
                  <p>וּבֹקֶר טוֹב</p>
                  <p>אָנוּ פּוֹתְחִים בִּידִיעָה</p>
                  <p>יְדִיעָה שֶׁמַּגִּיעָה</p>
                  <p>בַּדַּקּוֹת</p>
                  <p>מַמָּשׁ בַּדַּקּוֹת הָאחֲרוֹנוֹת</p>
                  <p>דּוּדוּ טוֹפָּז</p>
                  <p>נִסָּה לְהִתְאַבֵּד</p>
                  <p>הַבֹּקֶר בְִּתָּאוֹ</p>
                  <p>מִשֵּׁרוּת בָּתֵּי הַסֹּהַר נִמְסָר</p>
                  <p>כִּי הַבֹּקֶר נִצְפָה</p>
                  <p>כְּשֶׁהוּא בָּרִיא וְשָׁלֵם</p>
                  <p>וְאִלּוּ כַּעֲבֹר</p>
                  <p>כַּחֲצִי שָׁעָה</p>
                  <p>נִמְצָא תָּלוּי [...]</p>
                  <br />
                  <p>אָז הַרְבֵּה</p>
                  <p>לְחַדֵּשׁ בַּשָּׁלָב הַזֶּה אֵין</p>
                  <p>אֲנַחְנוּ עֲדַיִן</p>
                  <p>לֹא מְקַבְּלִים</p>
                  <p>יְדִיעוֹת רִשְׁמִיּוֹת</p>
                  <p>לְגַבֵּי מָה שֶׁקּוֹרֶה שָׁם</p>
                  <p>בְּתוֹךְ תָּא</p>
                  <p>הַשֵּׁרוּתִים שֶׁבַּכֶּלֶא</p>
                  <p>נְחַכֶּה לְהוֹדָעוֹת רִשְׁמִיּוֹת, כָּרֶגַע,</p>
                  <p>לְפִי מָה שֶׁאֲנַחְנוּ יוֹדְעִים</p>
                  <p>מַצָּבוֹ שֶׁל אָהְהְ מַצָּבוֹ שֶׁל</p>
                  <p>אָהְהְ לְגַבֵּי מַצָּבוֹ שֶׁל טוֹפָּז</p>
                  <p>כְּכָל הִַנִּרְאֶה הוּא...</p>
                  <p>הוּא לֹא טוֹב...</p>
                  <p>אֲנַחְנוּ לֹא יוֹדְעִים כָּרֶגַע לוֹמַר</p>
                  <p>בְּאֹפֶן רִשְׁמִי</p>
                  <p>הַאִם הוּא מֵת —</p>
                  <p>אוֹ לֹא מֵת [...]</p>
                </div>
              </div>
              <div className={style(classes.column, { stackable: true, columns: 2 })}>
                <div className={style(classes.figure)}>
                  <img
                    title={`שירים טיפוסיים &mdash; תוכן עניינים`}
                    src={tocExcerptPhoto}
                    className={style(classes.coverPhoto)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLayout(TypedPoemsPage);
